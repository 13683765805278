const space = {
  space: [
    0,
    '0.125rem',
    '0.25rem',
    '0.5rem',
    '1rem',
    '2rem',
    '4rem',
    '8rem',
    '16rem',
    '32rem',
    '1px'
  ]
}

export default space
